import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';

Sentry.init({
	dsn: 'https://aee6774791bdbef22b915e897ccecb4f@o4508304163733504.ingest.de.sentry.io/4508304165240912',

	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 1,

	environment: PUBLIC_ENVIRONMENT,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.s
	replaysOnErrorSampleRate: 1.0

	// If you don't want to use Session Replay, just remove the line below:
	//integrations: [replayIntegration()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
